@import "./theme.scss";

@font-face {
  font-family: satoshiLight;
  src: local("Satoshi-Light"), url("./../../assets/fonts/Satoshi/Satoshi-Light.ttf") format("truetype");
}

@font-face {
  font-family: satoshiBold;
  src: local("Satoshi-Bold"), url("./../../assets/fonts/Satoshi/Satoshi-Bold.ttf") format("truetype");
}

@font-face {
  font-family: satoshiReg;
  src: local("Satoshi-Regular"),
    url("./../../assets/fonts/Satoshi/Satoshi-Regular.ttf") format("truetype");
}

@font-face {
  font-family: satoshiBlack;
  src: local("Satoshi-Black"),
    url("./../../assets/fonts/Satoshi/Satoshi-Black.ttf") format("truetype");
}

@font-face {
  font-family: satoshiMedium;
  src: local("Satoshi-Medium"),
    url("./../../assets/fonts/Satoshi/Satoshi-Medium.ttf") format("truetype");
}

@font-face {
  font-family: sentientLight;
  src: local("Sentient-Light"),
    url("./../../assets/fonts/Sentient/Sentient-Light.ttf") format("truetype");
}

@font-face {
  font-family: sentientReg;
  src: local("Sentient-Bold"),
    url("./../../assets/fonts/Sentient/Sentient-Bold.ttf") format("truetype");
}

@font-face {
  font-family: sentientReg;
  src: local("Sentient-Regular"),
    url("./../../assets/fonts/Sentient/Sentient-Regular.ttf") format("truetype");
}

@font-face {
  font-family: satisfyReg;
  src: url("./../../assets/fonts/Satisfy/Satisfy-Regular.ttf") format("truetype");
}

$enable-smooth-scroll: false;

body {
  padding: 0;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 0.1px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $grey-text;
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $white;
}

::-webkit-scrollbar {
  display: none;
}

:host ::ng-deep .mat-tab-group.mat-primary .mat-ink-bar {

  background-color: red !important;

}

.mat-checkbox-h-20 {
  .mdc-form-field {
    height: 20px !important;
  }
}

.mat-radio-padding-0 {
  .mdc-radio {
    padding: 0 !important;
  }
}

.mat-bottom-sheet-container {
  padding: 0px !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.mat-bottom-sheet-container {
  padding: 0px !important;
  max-height: 100vh !important;
}

.custom-mat-form-field {
  .mat-mdc-form-field {
    width: 100% !important;
  }

  .mat-mdc-form-field-flex {
    height: 40px !important;
    align-items: center !important;
  }

  .mat-mdc-text-field-wrapper {
    width: 100% !important;
    height: 40px !important;
  }

  .mat-mdc-form-field-bottom-align::before {
    content: none;
    height: 0;
  }
}


button:disabled {
  opacity: 0.5 !important;
  border-color: $grey-text !important;
  color: $white !important;
}

.increase-item-count,
.plus-icon,
.minus-icon,
.decrease-item-count {
  cursor: pointer;
}

.mat-drawer {
  width: 62% !important;
}

.mat-drawer-content {
  padding: 0px !important;
}

.mat-calendar-padding-0 {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

// For custom carousel


.property-image {
  .carousel {
    overflow-x: hidden !important;
  }
}

.carousel {
  width: 100%;
  overflow-x: visible;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.inner_carousel {
  width: 100%;
  display: flex;
  position: relative;
  transition: right 1s;
}

.item_carousel {
  min-width: 100%;
  // cursor: pointer;
}

.prev-arrow_carousel {
  position: absolute;
  left: 10px;
  top: 40%;
  cursor: pointer;
  transform: rotate(180deg);
}

.next-arrow_carousel {
  position: absolute;
  right: 10px;
  top: 40%;
  cursor: pointer;
}

.indicators_carousel {
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 10px;

  li {
    border-radius: 5px;
    height: 7px;
    cursor: pointer;
  }

  .active-indicator_carousel {
    width: 20px;
    background-color: $theme-text-color;
  }

  .inactive-indicator_carousel {
    width: 14px;
    background-color: #B2B5BC;
  }
}

// Apply below classes for overlay
.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Custom styles for ng-autocomplete

.custom-autocomplete {
  .autocomplete-container {
    .input-container {
      input {
        height: 50px !important;
        border-radius: 6px !important;
      }
    }

    .suggestions-container {
      width: 100% !important;
      min-width: max-content !important;
      border-radius: 2px !important;
      top: 50px;
    }
  }
}

// Custom styling for mat dialog
.custom-dialog-container {
  .mat-dialog-container {
    // margin: 0px;
    padding: 0px;
  }
}

//

// .bordered-expansion-panel-content {
//   .mat-expansion-panel-content {
//     border-left: 1px solid rgba(0, 0, 0, 0.3) !important;
//     padding-left: 30px;
//   }
// }

// @media screen and (max-width: 480px) {
// .mat-expansion-panel-header {
//   padding-left: 10px !important;
//   padding-right: 10px !important;
//   padding-top: 0px !important;
//   padding-bottom: 0px !important;
// }

// .bordered-expansion-panel-content {
//   .mat-expansion-panel-content {
//     border-left: 1px solid rgba(0, 0, 0, 0.3) !important;
//     padding-left: 20px;
//   }
// }
// }
a[href^="http://maps.google.com/maps"] {
  display: none !important
}

a[href^="https://maps.google.com/maps"] {
  display: none !important
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.gmnoprint {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
  font-size: 50px !important;
}

.slick-prev {
  left: -60px !important;
}

.no-padding-mat-form-input,
.no-padding-mat-form-select {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

::ng-deep.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab .mat-tab {
  padding-right: 0px !important;
  padding-left: 0px !important;
}


.empty-part {
  display: flex;
  flex-direction: column;
  height: 50vh;
  align-items: center;
  justify-content: center;

  button {
    border: none;
    border-radius: 5px;
    padding: 10px;
    width: 40%;
    background-color: $theme-primary;
    @include text-style-op(16px, satoshiReg, $white, normal, 400, 1);
    cursor: pointer;
  }
}

.no-image {
  text-align: center;
  height: 150px;
  width: 150px;

  img {
    height: 100%;
    width: 100%;
  }
}

.empty-heading {
  @include text-style(18px, satoshiReg, $theme-text-color, normal, 600);
  text-align: center;
}

.empty-subtext,
.emptyrules-text {
  width: 90%;
  margin-top: 5px;
  margin-bottom: 10px;
  @include text-style-op(16px, satoshiReg, $theme-text-color, normal, 400, 0.7);
  text-align: center;
}

.listing-heading {
  background-color: #f4f4f4;
  padding: 10px 20px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;

  .heading-text {
    margin-left: 15px;
    @include text-style(18px, sentientReg, $theme-text-color, normal, 800);
  }
}

@media screen and (min-width: 480px) {
  .chip-type-list {
    justify-content: unset !important;
    gap: 10px !important;
  }

  .chip-type {
    min-width: 170px !important;
    padding: 10px 20px !important;
    @include text-style(18px !important, satoshiReg, $theme-text-color, normal, 600);
  }
}

.chip-type-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  width: auto;
  padding: 5px;
  padding-top: 10px;

  .chip-type {
    min-width: 150px;
    text-align: center;
    border-radius: 3px;
    padding: 5px 10px;
    margin-right: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    @include text-style(14px, satoshiReg, $theme-text-color, normal, 600);
  }

  .active {
    color: $white;
    background-color: #0b1625df;
  }

}

.borderClass {
  .mat-dialog-container {
    border-radius: 30px;
  }
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.mat-mdc-text-field-wrapper {
  height: 42px !important;
  display: flex !important;
  align-items: center !important;
}

.partnership-form {
  .mat-mdc-form-field-subscript-wrapper {
    display: unset !important;
  }

  .mat-mdc-text-field-wrapper {
    height: unset !important;
    display: unset !important;
    align-items: unset !important;
  }
}