// @use '~@angular/material' as mat;
$black: #000;
$white: #fff;
$theme-text-color: #0B1625;
$grey-text: #999;
$theme-primary: #C53053;
$bg-theme-primary-light: #FBF6FF;
$theme-primary-light: #056B9B;
$theme-secondary: #ff1872;
$grey-text: #999;
$light-grey: #f0f0f0;
$dark-background: #777a79;
$light-green: #E1F4F0;
$dark-green-text: #097D5F;
$light-gray-text: #ffffffc1;
$light-oranged: lightcoral;
$light-black-bg: rgba(0, 0, 0, 0.2);

$dark-purple-text: #5D1FAA;
$dark-blue-text: #044CA1;

$bg-light-gray-s2: #d9d9d944;
$bg-light-gray: #F3F4F4;
$bg-light-green: #B2EDDF;
$bg-light-purple: #E5F1F8;
$bg-dark-purple: #0B1625;

$bg-theme-yellow: #FFF5E7;
$text-theme-yellow: #FCB34A;

$bg-theme-green: #EDFFFA;
$text-theme-green: #097D5F;

$bg-theme-red: #FAEEF1;
$text-theme-red: #C53053;

$theme-primary-outline: rgb(84, 84, 245);
// @import "~@angular/material/theming";

// @include mat.core();

// $istharaErp-primary: mat.define-palette(mat.$pink-palette);
// $istharaErp-accent: mat.define-palette(mat.$light-green-palette, A700);

// $istharaErp-warn: mat.define-palette(mat.$red-palette);
// $istharaErp-primary-color: mat.get-color-from-palette($istharaErp-primary);
// $istharaErp-theme: mat.define-light-theme((color: (primary: $istharaErp-primary,
//         accent: $istharaErp-accent,
//         warn: $istharaErp-warn,
//       ),
//     ));
// $erp-checkbox-theme: mat.define-light-theme((color: (primary: mat.define-palette(mat.$grey-palette, 900),
//         accent: mat.define-palette(mat.$light-green-palette, A700),
//         warn: mat.define-palette(mat.$indigo-palette),
//       ),
//     ));

// @include mat.all-component-themes($istharaErp-theme);

// .dark-theme {
//   // This mixin only generates the color styles now.
//   @include mat.all-component-colors($erp-checkbox-theme);
// }

// mixins
@mixin text-style($size, $font, $color, $style, $weight) {
  font-family: $font;
  font-style: $style;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}

@mixin text-style-op($size, $font, $color, $style, $weight, $opacity) {
  font-family: $font;
  font-style: $style;
  font-weight: $weight;
  font-size: $size;
  color: $color;
  opacity: $opacity;
}